import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/getipport',
    name: 'getipport',
    component: () => import('../views/Getipport.vue'),
  },
  {
    path: '/genfromone',
    name: 'genfromone',
    component: () => import('../views/Genfromone.vue'),
  },
  {
    path: '/bulkmodify',
    name: 'bulkmodify',
    component: () => import('../views/Bulkmodify.vue'),
  },
  {
    path: '/getfromjson',
    name: 'getfromjson',
    component: () => import('../views/GetFromJson.vue'),
  },
]

const router = new VueRouter({
  routes,
})

export default router
