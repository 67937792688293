<template>
  <v-app id="app">
    <v-navigation-drawer v-model="drawer" app>
      <SidebarList />
    </v-navigation-drawer>

    <v-navigation-drawer right v-model="drawer2" app>
      <SidebarList2 />
    </v-navigation-drawer>

    <v-app-bar dense app>
      <div class="AppBarBox">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title @click="$router.push('/')"> vDecoder</v-toolbar-title>

        <v-app-bar-nav-icon @click="drawer2 = !drawer2"></v-app-bar-nav-icon>
      </div>

      <v-progress-linear
        :active="isLoading"
        :indeterminate="isLoading"
        absolute
        bottom
        color="red"
      ></v-progress-linear>
    </v-app-bar>

    <SnackBarComp />

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import SnackBarComp from '@/components/SnackBarComp.vue'
import SidebarList from './components/SidebarList.vue'
import SidebarList2 from './components/SidebarList2.vue'

import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    SnackBarComp,
    SidebarList,
    SidebarList2,
  },
  data: () => ({
    drawer: null,
    drawer2: null,
  }),
  computed: {
    ...mapState(['isLoading', 'selectIdx']),
  },
}
</script>

<style>
.theme--light.v-application {
  background: transparent !important;
}

.v-text-field__details {
  display: none !important;
}

/* v-radio-group and v-radio: */

.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.v-messages {
  display: none;
}

.AppBarBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>
