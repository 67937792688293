<template>
  <v-list shaped>
    <v-list-item-group color="primary">
      <v-list-item @click="itemClick('GetIpPort')">
        <v-list-item-content>
          <v-list-item-title
            >GetIpPort {{ '[' +arrIpPort.length + ']'}}</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="itemClick('BulkModify')">
        <v-list-item-content>
          <v-list-item-title>BulkModify</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="itemClick('GenFromOne')">
        <v-list-item-content>
          <v-list-item-title>GenFromOne</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="itemClick('GetFromJson')">
        <v-list-item-content>
          <v-list-item-title>GetFromJson</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['arrIpPort']),
  },
  methods: {
    itemClick(text) {
      this.$router.push(`/${text.toLowerCase()}`)
    },
  },
}
</script>

<style scoped>
.methodBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.methodCostBox {
  opacity: 0.3;
}
</style>
