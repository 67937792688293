<template>
  <div v-if="snackBarObj !== null" class="SnackBarCompBix">
    <v-snackbar
      v-model="snackBarFlag"
      :timeout="snackBarObj.sbTimeout"
      :color="sbColor"
      :min-width="0"
      bottom
    >
      {{ snackBarObj.sbMsg }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["snackBarObj"]),
    ...mapGetters(["getSnackBarFlag"]),

    sbColor() {
      if (this.snackBarObj.sbStatus) {
        // success
        return "rgba(76, 175, 80, 0.5)";
      } else {
        // fail
        return "rgba(216, 27, 96, 0.5)";
      }
    },

    snackBarFlag: {
      get() {
        return this.getSnackBarFlag;
      },
      set(value) {
        this.mutation_setSnackBarFlag(value);
      },
    },
  },
  methods: {
    ...mapMutations(["mutation_setSnackBarFlag"]),
  },

  mounted() {
    console.log("SnackBarComp-mounted");
  },
};
</script>
